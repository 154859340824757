<template>
  <div class="MyNotifications">
    <Spacer num="1" />
    <Title :label="$locale['my_notifications']" :class="{ xpadding: $isMobile || $isTablet }" />
    <Spacer num="1" />
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          class="MyNotificationsCard"
          v-for="(notification, index) in items"
          :key="index"
          :label="`<span third-bold>${notification.title}</span>`"
          :desc="`<div><div message>${notification.message}</div><div date>${date_locale(notification.date)}</div></div>`"
          :icon="false"
          :type="notification.type"
          :iconRight="notification.linkTo ? true : false"
          :prevent="notification.linkTo ? false : true"
          @click="linkTo(notification)"
        />
        <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
        <CardMessage v-if="!$loading && !statistics.totalItems && listed" :message="$locale['no_has_notifications']" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/notifications`,
      storeId: "my_notifications",
      items: [],
      statistics: { totalItems: 0, page: 1 },
      limit: 20,
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    loaded: function() {
      //this.$store.dispatch("reportReadNotifications");
    },
    linkTo: function(notification) {
      if (!notification.linkTo) return;

      if (!this.$links[notification.linkTo]) {
        this.$router.push(notification.linkTo).catch(() => {});
        return;
      }

      this.$router.push(this.$links[notification.linkTo]).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.MyNotifications {
  .MyNotificationsCard .LargeButtonDesc {
    opacity: 1;
  }

  .MyNotificationsCard [date] {
    opacity: 0.5;
    margin: $mpadding/4 0 0 0;
  }

  .MyNotificationsCard [warn] {
    background-color: var(--warn-color);
    color: #fff;
    margin: $mpadding/2 0 0 0;
    display: inline-block;
  }

  .MyNotificationsCard[type="reload"] [mount] {
    color: var(--color-tips);
  }
}
</style>
